<template>
  <b-modal
    id="modal-center"
    centered
    :visible="showModal"
    hide-footer
    size="lg"
    body-class="text-center pb-5"
    header-class="border-bottom-0"
    no-close-on-backdrop
  >
    <template v-slot:modal-header="">
      <b-button
        type="button"
        variant="default"
        class="p-0 ml-auto"
        @click="redirectHome"
      >
        <icon-close
          height="24"
          width="24"
        />
      </b-button>
    </template>
    <img
      src="../../assets/imagesv2/icon_email.png"
      alt=""
    >
    <h1
      v-if="accountType == 2"
      class="text-18 font-weight-bold py-0"
    >
      Thanks for your interest in joining the FanPopz
    </h1>
    <p
      v-else
      class="display-4 font-weight-bold"
    >
      Check your email
    </p>
    <b-row class="justify-content-center mb-3">
      <b-col
        v-if="accountType == 2"
        xl="10"
        lg="10"
        class="text-center confirm-email-text font-open-sans"
      >
        Just a reminder, you are not automatically enrolled on FanPopz. If you
        meet the requirements, a talent representative will contact you via text
        or email with your status and help you onboard.
      </b-col>
      <b-col
        v-else
        xl="5"
        lg="7"
        class="text-center confirm-email-text font-open-sans"
      >
        Confirm your account by clicking link we sent you at {{ regemail }}
      </b-col>
    </b-row>
    <router-link
      to="/"
      class="btn btn-golden rounded-pill px-5 mb-4"
    >
      GO TO HOMEPAGE
    </router-link>
  </b-modal>
</template>

<script>
import IconClose from "../../assets/svg-import/close.svg";
export default {
    name: "ConfirmAccount",
    components: { IconClose },
    props: {
        regemail: {
            type: String,
            default: "",
        },
        showModal: {
            type: Boolean,
            default: false,
        },
        accountType: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            useremail: this.regemail,
        };
    },
    methods: {
        handleShow() {
            this.$emit("handleShow", false);
        },
        redirectHome() {
            window.location.href = "/";
        },
    },
};
</script>
