<template>
  <section :class="[style['mobile-form-container']]">
    <LoadingSpinner v-if="loading" />
    <ConfirmAccount
      :showModal="showModal"
      :regemail="email"
      :accountType="selectedAccountType"
      @handleShow="handleShow($event)"
    />
    <crop 
      ref="cropRef" 
      :picPopup="picPopup"
      @cropProfilePhoto="updateSelectedProfPic" 
      @closePicPopup="resetProfilePic"
    />
    <div class="pb-md-4 mx-100">
      <div :class="[style['register_wrapper'], 'mb-md-5 my-md-4 p-3']">
        <router-link
          to="/"
          class="navbar-brand font-weight-bold text-center my-6 px-4 d-none d-lg-inline-block"
        >
          <img
            :src="logo"
            alt="fanpopz-logo"
            fluid
            class="register-logo"
          >
        </router-link>
        <b-row class="justify-content-lg-start mx-0">
          <b-col cols="12">
            <div class="register-wrapper-header text-left pl-4 pt-5 mb-5">
              <h1
                v-if="window.width > 768"
                :class="[
                  style['title'],
                  'font-weight-bold mb-4 text-left text-white',
                ]"
              >
                Register new account
              </h1>
              <h5
                v-else
                class="font-weight-bold mb-4 text-left text-white"
              >
                Register new account
              </h5>
              <div
                v-if="window.width > 768"
                class="h5 d-flex align-items-center justify-content-start mb-3 text-white"
              >
                <!-- Step
                <span
                  :class="[
                    style['step-count-number'],
                    'flex-shrink-0 mx-2 rounded-circle bg-primary text-white',
                  ]"
                >1</span>
                of 2 : Tell us about you -->
                Tell us about you
              </div>
              <p
                v-else
                class="mb-3 text-white"
              >
                Tell us about you
              </p>
            </div>
            <div class="register-wrapper-form">
              <div class="register-form pl-2">
                <form>
                  <b-row class="pb-5 mb-5 border-bottom mx-0">
                    <b-col
                      md="12"
                      xl="12"
                    >
                      <div class="d-flex h4 mb-4 font-weight-bold">
                        <span class="d-flex flex-column text-white">
                          <span>Account Type</span>
                        </span>
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      class="p-0"
                    >
                      <b-row class="justify-content-center mx-0">
                        <b-col
                          cols="11"
                          class="p-0"
                        >
                          <b-form-group
                            v-slot="{ ariaDescribedby }"
                            :class="['mx-md-3 mb-0', style['register-account']]"
                          >
                            <b-form-radio-group
                              v-model="selectedAccountType"
                              :options="accountTypeOptions"
                              :aria-describedby="ariaDescribedby"
                              name="radios-inline"
                              class="w-100"
                              :buttons="radioBtns"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row class="pb-5 mb-5 mx-0">
                    <b-col cols="12">
                      <div class="d-flex h4 mb-4 font-weight-bold">
                        <span class="d-flex flex-column">
                          <span class="text-white">Personal Information</span>
                        </span>
                      </div>
                    </b-col>
                    <b-col
                      lg="8"
                      cols="12"
                    >
                      <b-row
                        :class="[
                          'justify-content-center mx-0',
                          style['personal-info-form'],
                        ]"
                      >
                        <b-col
                          md="9"
                          class="pl-0"
                        >
                          <b-row class="mx-0">
                            <b-col
                              xl="4"
                              class="d-xl-none"
                            >
                              <b-form-file
                                v-if="selectedFile === null"
                                id="profile_pic_mobile"
                                ref="fileInput"
                                v-model="profile_pic"
                                v-validate="{
                                  required: true,
                                  size: 10240,
                                }"
                                :state="validateState('profile_pic')"
                                aria-describedby="input-1-live-profile_pic"
                                data-vv-as="Profile Picture"
                                accept="image/jpeg, image/png"
                                name="profile_pic"
                                :browse-text="''"
                                :class="[
                                  style['dropzone-custom-content'],
                                  'border rounded-12 font-open-sans text-center test2',
                                ]"
                                @change="previewFiles"
                              >
                                <template slot="placeholder">
                                  <div
                                    class="h-100 d-flex flex-column justify-content-center text-white"
                                  >
                                    <div>
                                      <upload-icon
                                        width="34"
                                        height="34"
                                        class="mr-2"
                                      />
                                    </div>
                                    <img
                                      src="../assets/svg-import/upload.svg"
                                      alt=""
                                      width="60"
                                      class="mb-3 mx-auto"
                                    >
                                    <p class="font-weight-bold mb-2">
                                      Your photo
                                    </p>
                                    <small
                                      class="pt-3 text-white font-weight-bold"
                                    >
                                      Browse to upload .jpg not larger than 10MB
                                    </small>
                                  </div>
                                </template>
                              </b-form-file>
                              <label
                                v-else
                                id="profile_pic_label"
                                for="profile_pic1"
                                :class="[
                                  style['dropzone-custom-content'],
                                  'border',
                                ]"
                              >
                                <b-img
                                  :src="selectedFile"
                                  fluid
                                  :class="[style['img-selected']]"
                                />
                              </label>
                              <b-form-file
                                id="profile_pic1"
                                type="file"
                                name="profile-pic"
                                class="d-none"
                                @change="previewFiles"
                              />
                              <b-form-invalid-feedback
                                id="input-1-live-profile_pic"
                              >
                                {{ veeErrors.first("profile_pic") }}
                              </b-form-invalid-feedback>
                            </b-col>
                            <b-col
                              lg="12"
                              class="font-open-sans font-weight-600 pl-0 mx-0"
                            >
                              <div class="form-group mb-3">
                                <b-form-group
                                  id="name_label"
                                  label-for="name"
                                >
                                  <b-form-input
                                    id="name"
                                    v-model="name"
                                    v-validate="{
                                      required: true,
                                      min: 3,
                                    }"
                                    :state="validateState('name')"
                                    aria-describedby="input-1-live-name"
                                    data-vv-as="Name"
                                    name="name"
                                    type="text"
                                    maxlength="50"
                                    placeholder="Name"
                                    class="form-control border-left-0 border-right-0 border-top-0 bg-transparent join-community"
                                  />
                                  <b-form-invalid-feedback
                                    id="input-1-live-name"
                                  >
                                    {{ veeErrors.first("name") }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </div>
                            </b-col>
                            <b-col
                              lg="12"
                              class="font-open-sans font-weight-600 pl-0 mx-0"
                            >
                              <div class="form-group mb-3">
                                <b-form-group
                                  id="surname_label"
                                  label-for="surname"
                                  class="bg-transparent"
                                >
                                  <b-form-input
                                    id="surname"
                                    v-model="surname"
                                    v-validate="{
                                      required: true,
                                      min: 3,
                                    }"
                                    :state="validateState('surname')"
                                    aria-describedby="input-1-live-surname"
                                    data-vv-as="Last Name"
                                    name="surname"
                                    type="text"
                                    maxlength="50"
                                    placeholder="Last Name"
                                    class="form-control border-left-0 border-right-0 border-top-0 bg-transparent join-community"
                                  />
                                  <b-form-invalid-feedback
                                    id="input-1-live-surname"
                                  >
                                    {{ veeErrors.first("surname") }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </div>
                            </b-col>
                            <b-col
                              lg="12"
                              class="font-open-sans font-weight-600 pl-0 mx-0"
                            >
                              <div class="form-group mb-3">
                                <b-form-group
                                  id="username_label"
                                  label-for="username"
                                  class="bg-transparent"
                                >
                                  <b-form-input
                                    id="username"
                                    v-model="username"
                                    v-validate="{
                                      required: true,
                                      min: 3,
                                    }"
                                    :state="validateState('username')"
                                    aria-describedby="input-1-live-username"
                                    data-vv-as="Username"
                                    name="username"
                                    type="text"
                                    maxlength="50"
                                    placeholder="Username"
                                    class="form-control border-left-0 border-right-0 border-top-0 bg-transparent join-community"
                                    @keyup="checkUsername"
                                  />
                                  <b-form-invalid-feedback
                                    id="input-1-live-username"
                                  >
                                    {{ veeErrors.first("username") }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </div>
                            </b-col>
                            <b-col
                              lg="12"
                              class="font-open-sans font-weight-600 pl-0 mx-0"
                            >
                              <div class="form-group mb-3">
                                <b-form-group
                                  id="email_label"
                                  label-for="email"
                                  class="bg-transparent"
                                >
                                  <b-form-input
                                    id="email"
                                    v-model="email"
                                    v-validate="{
                                      required: true,
                                      email: true,
                                    }"
                                    :state="validateState('email')"
                                    aria-describedby="input-1-live-email"
                                    data-vv-as="Email"
                                    name="email"
                                    type="email"
                                    placeholder="Email"
                                    class="form-control border-left-0 border-right-0 border-top-0 bg-transparent join-community"
                                  />
                                  <b-form-invalid-feedback
                                    id="input-1-live-email"
                                  >
                                    {{ veeErrors.first("email") }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </div>
                            </b-col>
                            <b-col
                              v-if="selectedAccountType === accountTypes.fan"
                              lg="12"
                              class="font-open-sans font-weight-600 pl-0 mx-0"
                            >
                              <div class="form-group mb-3">
                                <b-form-group
                                  id="password_label"
                                  label-for="password"
                                  class="mb-3 bg-transparent"
                                >
                                  <b-form-input
                                    id="password"
                                    v-model="password"
                                    v-validate="{
                                      required: true,
                                      min: 6,
                                      password: true,
                                    }"
                                    :state="validateState('password')"
                                    aria-describedby="input-1-live-password"
                                    data-vv-as="Password"
                                    name="password"
                                    type="password"
                                    placeholder="Password (ex:Test@123)"
                                    class="form-control border-left-0 border-right-0 border-top-0 bg-transparent join-community"
                                  />
                                  <b-form-invalid-feedback
                                    id="input-1-live-password"
                                  >
                                    {{ veeErrors.first("password") }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </div>
                            </b-col>
                            <b-col
                              lg="12"
                              class="font-open-sans font-weight-600 pl-0 mx-0"
                            >
                              <div class="form-group mb-3">
                                <b-form-group
                                  id="phone_number_label"
                                  label-for="phone_number"
                                  class="mb-3 bg-transparent"
                                >
                                  <b-form-input
                                    id="phone_number"
                                    v-model="phone_number"
                                    v-validate="{
                                      required: true,
                                      phone_number: true,
                                    }"
                                    :state="validateState('phone_number')"
                                    aria-describedby="input-1-live-phone_number"
                                    data-vv-as="Phone Number"
                                    name="phone_number"
                                    maxlength="15"
                                    :placeholder="phone_number_placeholder"
                                    class="form-control border-left-0 border-right-0 border-top-0 bg-transparent join-community"
                                  />
                                  <b-form-invalid-feedback
                                    id="input-1-live-phone_number"
                                  >
                                    {{ veeErrors.first("phone_number") }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </div>
                            </b-col>
                            <b-col
                              lg="12"
                              class="font-open-sans font-weight-600 pl-0 mx-0"
                            >
                              <div class="form-group mb-3">
                                <b-form-group
                                  v-if="
                                    selectedAccountType === accountTypes.fan
                                  "
                                  id="birthdate_label"
                                  label-for="birthdate"
                                  class="mb-3 bg-transparent"
                                >
                                  <datepicker
                                    id="birthdate"
                                    v-model="birthdate"
                                    v-validate="{
                                      required: true,
                                    }"
                                    placeholder="  Birthdate"
                                    :state="validateState('birthdate')"
                                    aria-describedby="input-1-live-birthdate"
                                    data-vv-as="Birthdate"
                                    name="birthdate"
                                    :disabledDates="disabledDates"
                                    format="MM/dd/yyyy"
                                    :bootstrap-styling="true"
                                    :calendar-button="true"
                                    :clear-button="true"
                                    calendar-button-icon="fa fa-calendar"
                                    clear-button-icon="fa fa-times"
                                    class="bg-transparent"
                                  />

                                  <div
                                    id="input-1-live-birthdate"
                                    class="valdiation_error_custom"
                                  >
                                    {{ veeErrors.first("birthdate") }}
                                  </div>
                                </b-form-group>
                              </div>
                            </b-col>
                            <b-col
                              v-if="
                                selectedAccountType ===
                                  accountTypes.celebrityBand
                              "
                              class="pl-0"
                            >
                              <b-form-group
                                id="country_label"
                                label-for="country"
                                class="bg-transparent"
                              >
                                <Select2
                                  id="country"
                                  v-model="country"
                                  v-validate="{ required: true }"
                                  class="form-control w-100 border-left-0 border-right-0 border-top-0 bg-transparent join-community pl-0"
                                  :options="countryoptions"
                                  name="country"
                                  placeholder="Country"
                                  :state="validateState('country')"
                                  aria-describedby="input-1-live-country"
                                  data-vv-as="country"
                                />
                                <span
                                  id="input-1-live-profile_pic"
                                  class="valdiation_error_custom"
                                >
                                  {{ veeErrors.first("country") }}
                                </span>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row
                            v-if="selectedAccountType === accountTypes.celebrityBand"
                            class="mx-0"
                          >
                            <b-col
                              lg="12"
                              cols="12"
                              class="pl-0"
                            >
                              <b-form-group
                                id="timezone_label"
                                label-for="timezone"
                                class="bg-transparent"
                              >
                                <Select2
                                  id="timezone"
                                  v-model="timezone"
                                  v-validate="{ required: true }"
                                  class="form-control w-100 border-left-0 border-right-0 border-top-0 bg-transparent join-community pl-0"
                                  :options="timezone_list"
                                  name="timezone"
                                  placeholder="Select Timezone"
                                  :state="validateState('timezone')"
                                  aria-describedby="input-1-live-timezone"
                                  data-vv-as="timezone"
                                />
                                <span
                                  id="input-1-live-profile_pic"
                                  class="valdiation_error_custom"
                                >
                                  {{ veeErrors.first("timezone") }}
                                </span>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row
                            v-if="selectedAccountType === accountTypes.fan"
                            class="mx-0"
                          >
                            <b-col
                              lg="6"
                              md="6"
                              cols="12"
                              class="pl-0"
                            >
                              <b-form-group
                                id="address_label"
                                label-for="address"
                                class="bg-transparent"
                              >
                                <vue-google-autocomplete
                                  id="map"
                                  ref="address"
                                  aria-describedby="input-1-live-addressData"
                                  placeholder="Select Address"
                                  :class="[
                                    'input-field',
                                    style['address-input'],
                                  ]"
                                  @placechanged="getAddressData"
                                />
                                <b-form-input
                                  v-show="false"
                                  id="address"
                                  v-model="address"
                                  v-validate="{ required: true }"
                                  :state="validateState('address')"
                                  aria-describedby="input-1-live-address"
                                  data-vv-as="Address"
                                  name="address"
                                  placeholder="Select Address"
                                  class="input-field form-control border-left-0 border-right-0 border-top-0 bg-transparent join-community"
                                />
                                <span
                                  id="input-1-live-address"
                                  class="valdiation_error_custom"
                                >
                                  {{ veeErrors.first("address") }}
                                </span>
                              </b-form-group>
                            </b-col>
                            <b-col
                              md="6"
                              cols="12"
                              lg="6"
                              class="pl-0 pl-md-2"
                            >
                              <b-form-group
                                id="country_label"
                                label-for="country"
                                class="bg-transparent"
                              >
                                <Select2
                                  id="country"
                                  v-model="country"
                                  v-validate="{ required: true }"
                                  class="form-control w-100 border-left-0 border-right-0 border-top-0 bg-transparent join-community pl-0"
                                  :options="countryoptions"
                                  name="country"
                                  placeholder="Select Country"
                                  :state="validateState('country')"
                                  aria-describedby="input-1-live-country"
                                  data-vv-as="country"
                                  @change="changeCountry"
                                />
                                <span
                                  id="input-1-live-profile_pic"
                                  class="valdiation_error_custom"
                                >
                                  {{ veeErrors.first("country") }}
                                </span>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row
                            v-if="selectedAccountType === accountTypes.fan"
                            class="mx-0"
                          >
                            <b-col
                              md="6"
                              cols="12"
                              lg="6"
                              class="pl-0"
                            >
                              <b-form-group
                                id="state_label"
                                label-for="state"
                                class="bg-transparent"
                              >
                                <Select2
                                  id="state"
                                  v-model="state"
                                  v-validate="{ required: true }"
                                  class="form-control w-100 border-left-0 border-right-0 border-top-0 bg-transparent join-community pl-0"
                                  :options="stateoptions"
                                  name="state"
                                  placeholder="Select state"
                                  :state="validateState('state')"
                                  aria-describedby="input-1-live-state"
                                  data-vv-as="state"
                                  @change="changeState"
                                />
                                <span
                                  id="input-1-live-profile_pic"
                                  class="valdiation_error_custom"
                                >
                                  {{ veeErrors.first("state") }}
                                </span>
                              </b-form-group>
                            </b-col>
                            <b-col
                              md="6"
                              cols="12"
                              class="pl-0 pl-md-2"
                            >
                              <b-form-group
                                id="city_label"
                                label-for="city"
                                class="bg-transparent"
                              >
                                <Select2
                                  id="city"
                                  v-model="city"
                                  v-validate="{ required: true }"
                                  class="form-control w-100 border-left-0 border-right-0 border-top-0 bg-transparent join-community pl-0"
                                  :options="cityoptions"
                                  name="city"
                                  placeholder="Select City"
                                  :state="validateState('city')"
                                  aria-describedby="input-1-live-city"
                                  data-vv-as="city"
                                />
                                <span
                                  id="input-1-live-profile_pic"
                                  class="valdiation_error_custom"
                                >
                                  {{ veeErrors.first("city") }}
                                </span>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row
                            v-if="selectedAccountType === accountTypes.fan"
                            class="mx-0"
                          >
                            <b-col
                              md="6"
                              cols="12"
                              class="pl-0"
                            >
                              <b-form-group
                                id="timezone_label"
                                label-for="timezone"
                                class="bg-transparent"
                              >
                                <Select2
                                  id="timezone"
                                  v-model="timezone"
                                  v-validate="{ required: true }"
                                  class="form-control w-100 border-left-0 border-right-0 border-top-0 bg-transparent join-community pl-0"
                                  :options="timezone_list"
                                  name="timezone"
                                  placeholder="Select Timezone"
                                  :state="validateState('timezone')"
                                  aria-describedby="input-1-live-timezone"
                                  data-vv-as="timezone"
                                />
                                <span
                                  id="input-1-live-profile_pic"
                                  class="valdiation_error_custom"
                                >
                                  {{ veeErrors.first("timezone") }}
                                </span>
                              </b-form-group>
                            </b-col>
                            <b-col
                              md="6"
                              cols="12"
                              class="pl-0 pl-md-2"
                            >
                              <b-form-group
                                id="zipcode_label"
                                label-for="zipcode"
                              >
                                <b-form-input
                                  id="zipcode"
                                  v-model="zipcode"
                                  v-validate="{ required: true }"
                                  name="zipcode"
                                  placeholder="Zipcode"
                                  :state="validateState('zipcode')"
                                  class="border-left-0 border-right-0 border-top-0 bg-transparent join-community pl-0"
                                />
                                <span
                                  id="input-1-live-profile_pic"
                                  class="valdiation_error_custom"
                                >
                                  {{ veeErrors.first("zipcode") }}
                                </span>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-form-group
                            v-if="
                              selectedAccountType === accountTypes.celebrityBand
                            "
                            id="find_you_label"
                            label-for="find_you"
                            class="bg-transparent"
                          >
                            <Select2
                              id="find_you"
                              v-model="find_you"
                              v-validate="{ required: true }"
                              class="form-control w-100 border-left-0 border-right-0 border-top-0 bg-transparent join-community pl-0"
                              name="find_you"
                              :options="socialMedia"
                              placeholder="Where can we find you?"
                              :state="validateState('find_you')"
                              aria-describedby="input-1-live-find_you"
                              data-vv-as="find_you"
                            />
                            <span
                              id="input-1-live-find_you"
                              class="valdiation_error_custom"
                            >
                              {{ veeErrors.first("find_you") }}
                            </span>
                          </b-form-group>
                          <b-form-group
                            v-if="
                              selectedAccountType === accountTypes.celebrityBand
                            "
                            id="your_handle_label"
                            label-for="your_handle"
                          >
                            <b-form-input
                              id="your_handle"
                              v-model="your_handle"
                              v-validate="{ required: true }"
                              name="your_handle"
                              placeholder="Your handle"
                              :state="validateState('your_handle')"
                              class="border-left-0 border-right-0 border-top-0 bg-transparent join-community pl-0"
                            />
                            <span
                              id="input-1-live-your_handle"
                              class="valdiation_error_custom"
                            >
                              {{ veeErrors.first("your_handle") }}
                            </span>
                          </b-form-group>
                          <b-form-group
                            v-if="
                              selectedAccountType === accountTypes.celebrityBand
                            "
                            id="followers_count_label"
                            label-for="followers_count"
                          >
                            <b-form-input
                              id="followers_count"
                              v-model="followers_count"
                              v-validate="{ required: true }"
                              name="followers_count"
                              placeholder="How many followers do you have?"
                              :state="validateState('followers_count')"
                              class="border-left-0 border-right-0 border-top-0 bg-transparent join-community pl-0"
                            />
                            <span
                              id="input-1-live-followers_count"
                              class="valdiation_error_custom"
                            >
                              {{ veeErrors.first("followers_count") }}
                            </span>
                          </b-form-group>
                          <b-form-group
                            v-if="
                              selectedAccountType === accountTypes.celebrityBand
                            "
                            id="about_label"
                            label-for="about"
                          >
                            <b-form-input
                              id="about_you"
                              v-model="about_you"
                              v-validate="{ required: true }"
                              name="about_you"
                              placeholder="Anything else we should know about you?"
                              :state="validateState('about_you')"
                              class="border-left-0 border-right-0 border-top-0 bg-transparent join-community pl-0"
                            />
                            <span
                              id="input-1-live-about_you"
                              class="valdiation_error_custom"
                            >
                              {{ veeErrors.first("about_you") }}
                            </span>
                          </b-form-group>
                          <div
                            v-if="show"
                            class="inputBox"
                          >
                            <label>About you</label>
                            <textarea
                              type="text"
                              required="valid"
                              placeholder="E.g. Singer, Movie Director, and American Actress"
                            />
                          </div>
                          <div :class="['mb-3', style['register-checkbox']]">
                            <b-form-checkbox
                              id="email_notification"
                              v-model="email_notification"
                              name="email_notification"
                              value="1"
                              unchecked-value=""
                              class="font-open-sans theme-checkbox custom-checkbox-success mb-2"
                            >
                              Email Notification
                            </b-form-checkbox>

                            <b-form-checkbox
                              id="in_app_notification"
                              v-model="in_app_notification"
                              name="in_app_notification"
                              value="1"
                              unchecked-value=""
                              class="font-open-sans theme-checkbox custom-checkbox-success mb-2"
                            >
                              In-app Notification
                            </b-form-checkbox>

                            <b-form-checkbox
                              id="accept_terms_label"
                              v-model="accept_terms"
                              v-validate="{
                                accept_terms: true,
                                required: true,
                              }"
                              aria-describedby="input-1-live-accept_terms"
                              data-vv-as="accept_terms"
                              name="accept_terms"
                              value="1"
                              unchecked-value=""
                              class="font-open-sans theme-checkbox custom-checkbox-success mb-2"
                            >
                              I hereby agree to the
                              <a
                                href="/termsandconditions"
                                class="text-dark font-weight-bold"
                              >Terms and Conditions</a>
                            </b-form-checkbox>
                            <span
                              id="input-1-live-accept_terms"
                              class="valdiation_error"
                            >
                              {{ veeErrors.first("accept_terms") }}
                            </span>

                            <b-form-checkbox
                              id="agree_process_personal_data_label"
                              v-model="agree_process_personal_data"
                              v-validate="{
                                agree_process_personal_data: true,
                                required: true,
                              }"
                              aria-describedby="input-1-live-agree_process_personal_data"
                              data-vv-as="agree_process_personal_data"
                              name="agree_process_personal_data"
                              value="1"
                              unchecked-value=""
                              class="font-open-sans theme-checkbox custom-checkbox-success"
                            >
                              I hereby agree to the processing of my personal
                              data for marketing purposes
                            </b-form-checkbox>

                            <span
                              id="input-1-live-agree_process_personal_data"
                              class="valdiation_error"
                            >
                              {{
                                veeErrors.first("agree_process_personal_data")
                              }}
                            </span>
                          </div>
                          <div class="text-center w-100 mb-5">
                            <b-overlay
                              :show="isLoading"
                              rounded
                              opacity="0.6"
                              spinner-small
                              spinner-variant="primary"
                              :class="[
                                'd-inline-block w-100',
                                style['register-spinner'],
                              ]"
                            >
                              <b-button
                                ref="button"
                                :disabled="isLoading"
                                variant="primary"
                                :class="[
                                  'px-5 w-100 align-items-center btn btn-primary px-4 mr-3 text-uppercase mt-5',
                                  style['join-btn'],
                                ]"
                                pill
                                @click="handleNext"
                              >
                                <span class="px-4">Register</span>
                              </b-button>
                            </b-overlay>
                            <div class="w-100 pt-3">
                              <span
                                class="text-white font-weight-normal"
                              >Already have an account?</span>
                              <router-link
                                to="/login"
                                class="text-white font-weight-bold"
                              >
                                Login
                              </router-link>
                            </div>
                          </div>
                        </b-col>
                        <b-col cols="3" />
                      </b-row>
                    </b-col>
                    <b-col
                      xl="4"
                      class="d-none d-xl-block"
                    >
                      <b-form-file
                        v-if="selectedFile === null"
                        id="profile_pic_desktop"
                        ref="fileInput"
                        v-model="profile_pic"
                        v-validate="{
                          required: true,
                          size: 10240,
                        }"
                        :state="validateState('profile_pic')"
                        aria-describedby="input-1-live-profile_pic"
                        data-vv-as="Profile Picture"
                        accept="image/jpeg, image/png"
                        name="profile_pic"
                        :browse-text="''"
                        :class="[
                          style['dropzone-custom-content'],
                          'border rounded-12 font-open-sans text-center test1',
                        ]"
                        @change="previewFiles"
                      >
                        <template slot="placeholder">
                          <div
                            class="h-100 d-flex flex-column justify-content-center text-white"
                          >
                            <div>
                              <upload-icon
                                width="34"
                                height="34"
                                class="mr-2"
                              />
                            </div>
                            <img
                              src="../assets/svg-import/upload.svg"
                              alt=""
                              width="60"
                              class="mb-3 mx-auto"
                            >
                            <p class="font-weight-bold mb-2">
                              Your photo
                            </p>
                            <small class="pt-3 text-white font-weight-bold">
                              Browse to upload .jpg not larger than 10MB
                            </small>
                          </div>
                        </template>
                      </b-form-file>
                      <label
                        v-else
                        id="profile_pic_label"
                        for="profile_pic1"
                        :class="[style['dropzone-custom-content'], 'border']"
                      >
                        <b-img
                          :src="selectedFile"
                          fluid
                          :class="[style['img-selected']]"
                        />
                      </label>
                      <b-form-file
                        id="profile_pic1"
                        type="file"
                        name="profile-pic"
                        class="d-none"
                        @change="previewFiles"
                      />
                      <b-form-invalid-feedback id="input-1-live-profile_pic">
                        {{ veeErrors.first("profile_pic") }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>
                </form>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment-timezone";
// import axios from "axios";
import ConfirmAccount from "../components/Register/ConfirmAccount";
import {
    // API_BASE_URL,
    BUCKET_NAME,
} from "../config/config";
import {
    // _sendResponse,
    s3,
} from "../js/common-function";
// import { APIUrlList } from "../js/api_url";
import style from "../styles/registration.module.scss";
import { Validator } from "vee-validate";
import Datepicker from "vuejs-datepicker";
import {
    setCityOptions,
    setStateOptions,
    registerUser,
    setCountryOptions,
} from "../api/register";
import { setTimezoneOptions } from "../api/meeting";
// import VueGoogleAutocomplete from 'vue-google-autocomplete';
import LoadingSpinner from "../components/Common/LoadingSpinner";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import logo from "../assets/logo/logo-white-text.png";
import uploadIcon from "../assets/svg-import/upload.svg";
import Crop from "../../src/components/Common/Crop.vue";
import { _sendResponse } from "../js/common-function";

Validator.extend("password", {
    getMessage:
    "Password should have at least 1 lowercase,1 uppercase,1 number and 1 symbol.",
    validate: (value) =>
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/.test(value),
});

Validator.extend("phone_number", {
    getMessage: "Please enter valid phone number.",
    validate: (value) =>
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
            value
        ),
});

Validator.extend("accept_terms", {
    getMessage: "Please accept terms and condition",
    validate: (value) => value,
});

Validator.extend("agree_process_personal_data", {
    getMessage: "Please allow condition to share personal data",
    validate: (value) => value,
});
const userAccounts = {
    fan: 1,
    celebrityBand: 2,
};

export default {
    name: "Register",
    components: {
        ConfirmAccount,
        Datepicker,
        VueGoogleAutocomplete,
        LoadingSpinner,
        uploadIcon,
        Crop
    },
    data() {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const maxDate = new Date(today);
        return {
            max: maxDate,
            disabledDates: {
                from: new Date(Date.now() - 8640000),
            },
            newdate: "",
            style,
            selectedAccountType: 1,
            accountTypes: { ...userAccounts },
            accountTypeOptions: [
                { text: "Fan", value: userAccounts.fan },
                { text: "Celebrity/Band", value: userAccounts.celebrityBand },
            ],
            show: false,
            role: "fan",
            showModal: false,
            account_type: 1,
            connected_via: 1,
            name: this.$route.query.name != undefined ? this.$route.query.name : "",
            surname: this.$route.query.surname ? this.$route.query.surname : "",
            email:
        this.$route.query.email != undefined ? this.$route.query.email : "",
            username: "",
            password: "",
            profile_pic: [],
            phone_number: null,
            about_you: null,
            accept_terms: "",
            agree_process_personal_data: "",
            timezone: "",
            errors: "",
            url: null,
            isLoading: false, // join button click load
            selectedFile: null,
            params: {},
            birthdate: "",
            email_notification: "",
            in_app_notification: "",
            hideHeader: true,
            showDecadeNav: false,
            address: "",
            addressData: "",
            country: "",
            country_list: [],
            state: "",
            city: "",
            state_list: [],
            city_list: [],
            timezone_list: [],
            timezone_list_offset: [],
            profile_picture: "",
            zipcode: "",
            timezone_offset: "",
            address_line: "Kalavad Road",
            loading: false,
            countryoptions: [],
            stateoptions: [],
            cityoptions: [],
            countrynames: [],
            statenames: [],
            citynames: [],
            countrycodes: [],
            customercountrycode: "",
            logo,
            phone_number_placeholder: "Phone number",
            followers_count: "",
            your_handle: "",
            find_you: "",
            socialMedia: [
                { text: "Twitter", id: "twitter" },
                { text: "Instagram", id: "instagram" },
                { text: "YouTube", id: "youtube" },
                { text: "Facebook", id: "facebook" },
                { text: "Twitch", id: "twitch" },
                { text: "TikTok", id: "tiktok" },
                { text: "Other", id: "other" },
            ],
            window: {
                width: 0,
                height: 0,
            },
            picPopup: false,
            radioBtns: true
        };
    },
    watch: {
        selectedAccountType(val) {
            if (val === this.accountTypes.fan) {
                this.phone_number_placeholder = "Phone number";
            } else if (val === this.accountTypes.celebrityBand) {
                this.phone_number_placeholder = "Phone number (Never shared)";
            }
        },
    },
    async created() {
        let timezones = await setTimezoneOptions();
        this.timezone_list = timezones.timezones2;
        this.timezone_list_offset = timezones.offset;
        let countries = await setCountryOptions();
        this.country_list = countries.countries;

        let allcountries = await setCountryOptions();
        if (allcountries.countries.length > 0) {
            this.countryoptions = allcountries.countries;
            this.countrynames = allcountries.countriesnames;
            this.countrycodes = allcountries.countriescode;
        }
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        validateState(ref) {
            if (
                this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        /* async setStateOptions() {
            this.loading = true;
            let states = await setStateOptions(this.country);
            if (states.length > 0) {
                this.loading = false;
                this.state_list = states;
                this.city_list = [];
            } else {
                this.loading = false;
            }
        },
        async setCityOptions() {
            this.loading = true;
            let cities = await setCityOptions(this.country, this.state);
            if (cities.length > 0) {
                this.loading = false;
                this.city_list = cities;
            } else {
                this.loading = false;
            }
        }, */
        /* getAddressData(addressData, placeResultData, id) {
            // eslint-disable-next-line no-console
            console.log(addressData, placeResultData, id, "resultsss");
        }, */
        /* setUsername() {
            var name = this.name.trim();
            var surname = this.surname.trim();

            var username = name + "" + surname;
            this.username = username.toLowerCase().split(" ").join("");
        }, */
        checkUsername() {
            this.username = this.username
                .toLowerCase()
                .replace(/[^\w\s]/gi, "")
                .split(" ")
                .join("");
        },
        async handleNext() {
            // let me = this;
            this.isLoading = true;
            var googleAddress = document.getElementById("map");
            if (googleAddress) {
                googleAddress = googleAddress.value;
            }
            if (
                (googleAddress == "" || googleAddress == null) &&
        this.address != ""
            ) {
                this.address = "";
            }
            this.$validator.validateAll().then(async (result) => {
                if (!result) {
                    this.isLoading = false;
                    const firstField = Object.keys(this.veeErrors.collect())[0];

                    if (firstField == "profile_pic") {
                        var profPicMobile = document.getElementById("profile_pic_mobile");
                        if (profPicMobile){
                            profPicMobile.scrollIntoView();
                        }
                        var profPicDesktop = document.getElementById("profile_pic_desktop");
                        if (profPicDesktop){
                            profPicDesktop.scrollIntoView();
                        }
                        //document.getElementById("profile_pic").scrollIntoView();
                    } else {
                        document.getElementById(firstField + "_label").scrollIntoView();
                    }
                    return;
                }

                let objToSend = {};
                if (this.selectedAccountType == 2) {
                    objToSend = {
                        profile_picture: this.profile_picture,
                        username: this.username,
                        name: this.name,
                        surname: this.surname,
                        email: this.email,
                        account_type: this.selectedAccountType,
                        phone_number: this.phone_number,
                        about_you: this.about_you,
                        accept_terms: this.accept_terms,
                        connected_via: this.connected_via,
                        agree_process_personal_data: this.agree_process_personal_data,
                        email_notification: this.email_notification,
                        in_app_notification: this.in_app_notification,
                        country_id: this.country,
                        no_of_followers: this.followers_count,
                        your_handle: this.your_handle,
                        find_you: this.find_you,
                        timezone_id: this.timezone,
                    };
                } else {
                    objToSend = {
                        birthdate: moment(this.birthdate).format("MM/DD/YYYY"),
                        profile_picture: this.profile_picture,
                        username: this.username,
                        name: this.name,
                        surname: this.surname,
                        email: this.email,
                        password: this.password,
                        account_type: this.selectedAccountType,
                        phone_number: this.phone_number,
                        accept_terms: this.accept_terms,
                        connected_via: this.connected_via,
                        agree_process_personal_data: this.agree_process_personal_data,
                        timezone: moment.tz.guess(),
                        email_notification: this.email_notification,
                        in_app_notification: this.in_app_notification,
                        country_id: this.country,
                        state_id: this.state,
                        city_id: this.city,
                        zip_code: this.zipcode,
                        timezone_id: this.timezone,
                        address_line: this.address,
                    };
                }
                let registered = await registerUser(objToSend, "uploads/users/");
                if (registered.status) {
                    this.isLoading = false;
                    this.showModal = true;
                } else {
                    this.isLoading = false;
                }
            });
        },
        deleteOns3() {
            s3.deleteObject(
                {
                    Bucket: BUCKET_NAME,
                    Key: this.params.Key,
                },
                function () {}
            );
        },
        async previewFiles(event) {
            var checkfile = await this.profileCheck(event.target.files[0]);

            if (checkfile === false) {
                _sendResponse(
                    "error",
                    "Invalid profile picture: The profile picture must be a .jpeg, .jpg or .png file."
                );
                return false;
            }
            const currentTarget = event.currentTarget;
            if (currentTarget.nodeName.toLowerCase() === "div") {
                this.url = URL.createObjectURL(
                    event.currentTarget.querySelector("input").files[0]
                );
            } else {
                this.url = URL.createObjectURL(event.currentTarget.files[0]);
            }
            this.selectedFile = this.url;
            this.profile_picture = event.target.files[0];
            this.picPopup = true;
            this.$refs.cropRef.bindFile(this.selectedFile);
            
        },
        handleShow() {
            this.showModal = false;
        },
        getAddressData: async function (addressData, placeResultData) {
            this.loading = true;
            let street_number =
        addressData.street_number === undefined
            ? ""
            : addressData.street_number;
            let addressroute =
        addressData.route === undefined ? "" : addressData.route;
            let locality =
        addressData.locality === undefined ? "" : addressData.locality;
            let administrative_area_level_1 =
        addressData.administrative_area_level_1 === undefined
            ? ""
            : addressData.administrative_area_level_1;
            let addresscountry =
        addressData.country === undefined ? "" : addressData.country;

            this.address =
        street_number +
        " " +
        addressroute +
        ", " +
        locality +
        " " +
        administrative_area_level_1 +
        ", " +
        addresscountry;
            this.latitude =
        addressData.latitude === undefined ? "" : addressData.latitude;
            this.longitude =
        addressData.longitude === undefined ? "" : addressData.longitude;
            this.zipcode =
        addressData.postal_code === undefined ? "" : addressData.postal_code;

            if (addresscountry != "") {
                let placecountry =
          this.countrynames[addresscountry.toLowerCase().trim()];
                this.country = placecountry;

                let allstates = await setStateOptions(this.country);
                if (allstates.states.length > 0) {
                    this.stateoptions = allstates.states;
                    this.statenames = allstates.statenames;

                    let allcities = await setCityOptions(this.country, this.state);
                    if (allcities.cities.length > 0) {
                        this.cityoptions = allcities.cities;
                        this.citynames = allcities.citynames;
                    }
                }

                let statename = "";
                let cityname = "";
                let cityshortname = "";
                let localityshortname = "";
                placeResultData.address_components.map(async (value) => {
                    if (value.types[0] == "administrative_area_level_1") {
                        statename = value.long_name;
                    }

                    if (value.types[0] == "administrative_area_level_2") {
                        cityname = value.long_name;
                        cityshortname = value.short_name;
                    }

                    if (value.types[0] == "locality" || value.types[0] == "postal_town") {
                        localityshortname = value.short_name;
                    }
                });

                if (statename != "") {
                    let placestate = this.statenames[statename.toLowerCase().trim()];
                    this.state = placestate;

                    let allcities = await setCityOptions(this.country, this.state);
                    if (allcities.cities.length > 0) {
                        this.cityoptions = allcities.cities;
                        this.citynames = allcities.citynames;

                        if (cityname != "") {
                            let placecity = this.citynames[cityname.toLowerCase().trim()];
                            this.city = placecity === undefined ? "" : placecity;

                            if (
                                this.city == "" ||
                this.city == null ||
                this.city == undefined
                            ) {
                                let placecityshort =
                  this.citynames[cityshortname.toLowerCase().trim()];
                                let localityshort =
                  this.citynames[localityshortname.toLowerCase().trim()];

                                let addresscity =
                  placecityshort === undefined ? "" : placecityshort;
                                this.city = addresscity;
                                let googlecityoptions = this.cityoptions;
                                //set locality name as city name if administrative_area_level_2 is not in our cities list
                                if (
                                    googlecityoptions.filter((e) => e.text === addresscity)
                                        .length == 0
                                ) {
                                    this.city = localityshort === undefined ? "" : localityshort;
                                }
                            }
                        }
                    }
                }
            } else {
                this.country = "";
                this.state = "";
                this.city = "";
                this.zipcode = "";
                this.stateoptions = [];
                this.statenames = [];
                this.cityoptions = [];
                this.citynames = [];
            }

            this.loading = false;
        },
        async changeCountry(event) {
            this.loading = true;
            this.country = event;
            this.state = "";
            this.city = "";
            this.zipcode = "";
            this.stateoptions = [];
            this.statenames = [];
            this.cityoptions = [];
            this.citynames = [];
            //this.teleinput.setCountry(this.countrycodes[this.country]);

            let allstates = await setStateOptions(this.country);
            if (allstates.states.length > 0) {
                this.stateoptions = allstates.states;
                this.statenames = allstates.statenames;
            }

            this.loading = false;
        },
        async changeState(event) {
            this.loading = true;
            this.state = event;
            this.city = "";
            this.zipcode = "";
            this.cityoptions = [];
            this.citynames = [];

            let allcities = await setCityOptions(this.country, this.state);
            if (allcities.cities.length > 0) {
                this.cityoptions = allcities.cities;
                this.citynames = allcities.citynames;
            }

            this.loading = false;
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        updateSelectedProfPic(pic){
            this.profile_picture = pic;
            this.picPopup = false;
            this.url = URL.createObjectURL(pic);
            this.selectedFile = this.url;
        },
        async profileCheck(file) {
            var filetype = file.type;
            var fileextension = filetype.split("/").pop();
            var arr = ["jpg", "jpeg", "png"];

            var check = arr.includes(fileextension);

            return check;
        },
        resetProfilePic() {
            this.selectedFile = null,
            this.profile_picture = "";
            this.picPopup = false;
        }
  
    },
};
</script>
